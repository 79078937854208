import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { OrganizationType } from '../enums/organization-type';
import { ProductLabelDimensions } from '../enums/product-label-dimensions';
import { Role } from '../enums/role';
import { Organization } from '../interfaces/organization';
import { OrganizationDto } from '../interfaces/organization.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { organizationTypeToString } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  organization: Organization | null;
  show: boolean;
  closeModal: (organization: Organization | null) => void;
}

const OrganizationModal = (props: Props) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const defaultValues = useMemo(() => {
    const values: OrganizationDto = {
      name: '',
      cif: '',
      businessName: '',
      address: '',
      phone: '',
      email: '',
      enabledOnlineStores: false,
      invoicePaymentDetail: '',
      url: '',
      postalCode: '',
      city: '',
      province: '',
      country: '',
      type: OrganizationType.B2C,
      productLabelDimensions: ProductLabelDimensions._60x18,
    };
    if (props.organization) {
      values.name = props.organization.name;
      values.cif = props.organization.cif;
      values.businessName = props.organization.businessName;
      values.address = props.organization.address;
      values.phone = props.organization.phone;
      values.email = props.organization.email;
      values.enabledOnlineStores = props.organization.enabledOnlineStores;
      values.invoicePaymentDetail = props.organization.invoicePaymentDetail;
      values.url = props.organization.url;
      values.postalCode = props.organization.postalCode;
      values.city = props.organization.city;
      values.province = props.organization.province;
      values.country = props.organization.country;
      values.type = props.organization.type;
    }
    return values;
  }, [props.organization]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  useEffect(() => {
    if (props.show && props.organization) {
      reset(props.organization);
    }
  }, [props.show, props.organization, reset]);

  const close = () => {
    reset();
    props.closeModal(null);
  };

  const onSubmit = async (data: OrganizationDto) => {
    try {
      if (props.organization) {
        const updatedOrganization: Organization = await api.updateOrganization(props.organization.id, data);
        props.closeModal(updatedOrganization);
      } else {
        const newOrganization: Organization = await api.createOrganization(data);
        props.closeModal(newOrganization);
      }
      reset();
    } catch (e: any) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError?.response) {
        if (Array.isArray(axiosError.response.data.message)) {
          myToastr.error(axiosError.response.data.message.join('</br>'));
        } else {
          myToastr.error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Modal className="vercomi-modal my-form organization-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Organización</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={'input-name ' + (errors?.type ? 'error' : '')}>Tipo *</div>
              <select {...register('type', { required: true })} className={clsx({ error: errors?.type })} disabled={user.role !== Role.SuperAdmin}>
                <option value={OrganizationType.ALL}>{organizationTypeToString(OrganizationType.ALL)}</option>
                <option value={OrganizationType.B2B}>{organizationTypeToString(OrganizationType.B2B)}</option>
                <option value={OrganizationType.B2C}>{organizationTypeToString(OrganizationType.B2C)}</option>
              </select>
              {errors.type && <div className="error-message">{errors.type.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.name ? 'error' : '')}>Nombre *</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.businessName })}>Razón Social *</div>
              <input type="text" {...register('businessName', { required: true })} placeholder="Razón social" className={clsx({ error: errors?.businessName })} />
              {errors.businessName && <div className="error-message">{errors.businessName.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.phone })}>Teléfono *</div>
              <input type="number" {...register('phone', { required: true })} placeholder="Teléfono" className={clsx({ error: errors?.phone })} />
              {errors.phone && <div className="error-message">{errors.phone.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.cif })}>CIF *</div>
              <input type="text" {...register('cif', { required: true })} className={clsx({ error: errors?.cif })} placeholder="CIF" />
              {errors.cif && <div className="error-message">{errors.cif.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.address })}>Dirección *</div>
              <input type="text" {...register('address', { required: true })} className={clsx({ error: errors?.address })} placeholder="Dirección" />
              {errors.address && <div className="error-message">{errors.address.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.email })}>Email *</div>
              <input type="email" {...register('email', { required: true })} className={clsx({ error: errors?.email })} placeholder="Email" />
              {errors.email && <div className="error-message">{errors.email.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.postalCode })}>Código postal *</div>
              <input type="text" {...register('postalCode', { required: true })} className={clsx({ error: errors?.postalCode })} placeholder="Código postal" />
              {errors.postalCode && <div className="error-message">{errors.postalCode.message}</div>}
            </div>
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.city })}>Ciudad *</div>
              <input type="text" {...register('city', { required: true })} className={clsx({ error: errors?.city })} placeholder="Ciudad" />
              {errors.city && <div className="error-message">{errors.city.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.province })}>Provincia *</div>
              <input type="text" {...register('province', { required: true })} className={clsx({ error: errors?.province })} placeholder="Provincia" />
              {errors.province && <div className="error-message">{errors.province.message}</div>}
            </div>
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.country })}>País *</div>
              <input type="text" {...register('country', { required: true })} className={clsx({ error: errors?.country })} placeholder="País" />
              {errors.country && <div className="error-message">{errors.country.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.url })}>Url</div>
              <input type="text" {...register('url')} className={clsx({ error: errors?.url })} placeholder="Url" />
              {errors.url && <div className="error-message">{errors.url.message}</div>}
            </div>
            {watch('type') === OrganizationType.B2C && (
              <div className="col-6">
                <div className={clsx('input-name')}>Tiendas Online</div>
                <input type="checkbox" {...register('enabledOnlineStores')} disabled={user.role === Role.Admin || user.role === Role.Manager} />
              </div>
            )}
          </div>
          {user.role === Role.SuperAdmin && (
            <div className="row my-3">
              <div className="col-6">
                <div className="input-name">Dimensiones etiqueta producto</div>
                <select {...register('productLabelDimensions')}>
                  <option value={ProductLabelDimensions._60x18}>{ProductLabelDimensions._60x18}</option>
                  <option value={ProductLabelDimensions._60x18s3}>{ProductLabelDimensions._60x18s3}</option>
                  <option value={ProductLabelDimensions._38x21}>{ProductLabelDimensions._38x21}</option>
                </select>
              </div>
            </div>
          )}
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.invoicePaymentDetail })}>Detalle pago facturas *</div>
              <textarea {...register('invoicePaymentDetail', { required: true })} className={clsx({ error: errors?.invoicePaymentDetail })} placeholder="Texto que aparecerá en el pdf de la factura" />
              {errors.invoicePaymentDetail && <div className="error-message">{errors.invoicePaymentDetail.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default OrganizationModal;
