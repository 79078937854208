import { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Plus, Trash2, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { StoreType } from '../enums/store-type';
import { Organization } from '../interfaces/organization';
import { PaymentMethod } from '../interfaces/payment-method';
import { PaymentMethodDto } from '../interfaces/payment-method.dto';
import { Store } from '../interfaces/store';
import { StoreDto } from '../interfaces/store.dto';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { api } from '../services/api';
import myToastr from '../services/toastr';
import { getUserInfo } from '../store/auth-slice';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { setStoreId, storeSelector } from '../store/store-slice';

interface Props {
  storeId: number | undefined;
  show: boolean;
  closeModal: (store: Store | null) => void;
}

const styles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#252e3c',
    fontFamily: 'Inter-SemiBold',
    fontSize: '14px',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    height: '35px',
    color: 'white',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '10px',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    marginTop: '11px',
  }),
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: state.isFocused ? '10px' : '0px',
    };
  },
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '10px',
    backgroundColor: '#252e3c',
  }),
  clearIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      color: 'white',
    };
  },
};

const defaultValues: StoreDto = {
  name: '',
  phone: '',
  address: '',
  posMoney: 0,
  organizationId: -1,
  salePrefix: '',
  ticketDescription: '',
  businessName: '',
  cif: '',
  equivalenceSurcharge: false,
  invoicePrefix: '',
  rectificationInvoicePrefix: '',
  userIds: [],
  type: StoreType.B2C,
};

const StoreModal = (props: Props) => {
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const dispatch = useDispatch();
  const { selectedStoreId } = useSelector(storeSelector);
  const [requesting, setRequesting] = useState(false);
  const [store, setStore] = useState<Store | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const logoInputFileRef = useRef<any>(null);
  const qrInputFileRef = useRef<any>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const [qr, setQr] = useState<File | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [qrUrl, setQRUrl] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<{ label: string; value: number }[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const {
    handleSubmit: handleSubmitPm,
    register: registerPm,
    reset: resetPm,
    formState: { errors: errorsPm },
    getValues: getValuesPm,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: { name: '', isTpv: true },
  });

  const organizationUsersSelectOptions: { label: string; value: number }[] = useMemo(
    () =>
      users
        .filter((user: User) => {
          const userInOrganization: UserInOrganization | undefined = user.userInOrganizations.find((uio: UserInOrganization) => uio.organizationId === organization.id);
          if (!userInOrganization) {
            return false;
          }
          return userInOrganization.role !== Role.SuperAdmin;
        })
        .map((user: User) => ({ label: `${user.name ?? ''} ${user.surnames ?? ''}`.trim(), value: user.id })),
    [organization.id, users],
  );

  useEffect(() => {
    if (!props.show) {
      return;
    }
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const getUsers = async () => {
    const users: User[] = await api.getUsers(organization.id);
    setUsers(users);
  };

  const getStore = async () => {
    setRequesting(false);
    const store: Store = await api.getStore(props.storeId!);
    store.paymentMethods.sort((a: PaymentMethod, b: PaymentMethod) => {
      if (a.removable && !b.removable) {
        return 1;
      } else if (!a.removable && b.removable) {
        return -1;
      } else {
        return 0;
      }
    });
    setStore(store);
    setSelectedUsers(store.users.map((seller: User) => ({ label: `${seller.name} ${seller.surnames}`, value: seller.id })));
    setRequesting(false);
    if (store.logoPath && store.logoPath.length > 0) {
      setLogoUrl(`${process.env.REACT_APP_PUBLIC_URL}${store.logoPath}`);
    }
    if (store.qrPath && store.qrPath.length > 0) {
      setQRUrl(`${process.env.REACT_APP_PUBLIC_URL}${store.qrPath}`);
    }
  };

  useEffect(() => {
    if (props.storeId) {
      getStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.storeId]);

  useEffect(() => {
    if (props.show) {
      if (store) {
        reset(store);
      }
    } else {
      setStore(null);
      reset(defaultValues);
      setSelectedUsers([]);
      setLogo(null);
      setQr(null);
      setLogoUrl(null);
      setQRUrl(null);
    }
  }, [props.show, store, reset]);

  const close = () => {
    props.closeModal(null);
  };

  const onSubmit = async (data: any) => {
    try {
      data.type = organizationType;
      data.organizationId = organization.id;
      data.userIds = selectedUsers.map((u: { label: string; value: number }) => u.value);
      data.posMoney = parseFloat(data.posMoney);
      if (store) {
        let updateStore: Store = await api.updateStore(store.id, data);
        if (logo) {
          updateStore = await api.updateStoreLogo(updateStore.id, logo);
        }
        if (qr) {
          updateStore = await api.updateQRStore(updateStore.id, qr);
        }
        updateStore.paymentMethods.sort((a: PaymentMethod, b: PaymentMethod) => {
          if (a.removable && !b.removable) {
            return 1;
          } else if (!a.removable && b.removable) {
            return -1;
          } else {
            return 0;
          }
        });
        const newPaymentMethodDto = getValuesPm();
        if (newPaymentMethodDto?.name && newPaymentMethodDto.name.length > 0) {
          await createPaymentMethod(newPaymentMethodDto);
        }
        if (store.id === selectedStoreId) {
          dispatch(setStoreId(updateStore.id));
        }
        myToastr.info(data.type === StoreType.B2C ? 'Tienda actualizada correctamente' : 'Almacén actualizado correctamente');
        props.closeModal(updateStore);
      } else {
        const newStore: Store = await api.createStore(data);
        newStore.paymentMethods.sort((a: PaymentMethod, b: PaymentMethod) => {
          if (a.removable && !b.removable) {
            return 1;
          } else if (!a.removable && b.removable) {
            return -1;
          } else {
            return 0;
          }
        });
        myToastr.success(data.type === StoreType.B2C ? 'Tienda creada correctamente' : 'Almacén creado correctamente');
        props.closeModal(newStore);
      }
      dispatch(getUserInfo());
    } catch (e: any) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError?.response) {
        if (Array.isArray(axiosError.response.data.message)) {
          myToastr.error(axiosError.response.data.message.join('</br>'));
        } else {
          myToastr.error(e.response.data.message);
        }
      }
    }
  };

  const deletePaymentMethod = async (id: number) => {
    setRequesting(true);
    await api.deletePaymentMethod(id);
    const s: Store = Object.assign({}, store);
    s.paymentMethods = s.paymentMethods.filter((pm: PaymentMethod) => pm.id !== id);
    setStore(s);
    setRequesting(false);
    if (store?.id === selectedStoreId) {
      dispatch(setStoreId(store.id));
    }
    myToastr.info('Método de pago eliminado correctamente');
  };

  const createPaymentMethod = async (data: { name: string; isTpv: boolean }) => {
    setRequesting(true);
    const paymentMethodDto: PaymentMethodDto = {
      name: data.name,
      isTpv: data.isTpv,
      storeId: store!.id,
    };
    const pm: PaymentMethod = await api.createPaymentMethod(paymentMethodDto);
    const s: Store = Object.assign({}, store);
    s.paymentMethods.push(pm);
    setStore(s);
    setRequesting(false);
    resetPm();
    myToastr.success('Método de pago creado correctamente');
    if (store?.id === selectedStoreId) {
      dispatch(setStoreId(store.id));
    }
  };

  const onChangeInputLogo = (e: any) => {
    if (e?.target?.files && e.target.files.length > 0) {
      setLogo(e.target.files[0]);
      setLogoUrl(URL.createObjectURL(e.target.files[0]));
    } else if (logoUrl) {
      setLogo(null);
      URL.revokeObjectURL(logoUrl!);
      setLogoUrl(null);
    }
  };

  const onChangeInputQR = (e: any) => {
    if (e?.target?.files && e.target.files.length > 0) {
      setQr(e.target.files[0]);
      setQRUrl(URL.createObjectURL(e.target.files[0]));
    } else if (qrUrl) {
      setQr(null);
      URL.revokeObjectURL(qrUrl!);
      setQRUrl(null);
    }
  };

  const onDeleteLogo = async () => {
    setLogo(null);
    if (logoUrl && logoUrl.length > 0) {
      URL.revokeObjectURL(logoUrl!);
      setLogoUrl(null);
    }
    if (store!.logoPath && store!.logoPath.length > 0) {
      const updatedStore: Store = await api.deleteStoreLogo(store!.id);
      setStore(updatedStore);
      myToastr.info('Logo eliminado correctamente');
    }
  };

  const onDeleteQR = async () => {
    setQr(null);
    if (qrUrl && qrUrl.length > 0) {
      URL.revokeObjectURL(qrUrl!);
      setQRUrl(null);
    }
    if (store!.logoPath && store!.logoPath.length > 0) {
      const updatedStore: Store = await api.deleteStoreLogo(store!.id);
      setStore(updatedStore);
      myToastr.info('QR eliminado correctamente');
    }
  };

  return (
    <Modal className="vercomi-modal my-form store-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <div className="content">
        <form id="store-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between mb-3">
            <div className="title">{organizationType === OrganizationType.B2C ? 'Tienda' : 'Almacén'}</div>
            <button type="button" className="close-button-modal" disabled={requesting} onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={'input-name ' + (errors?.name ? 'error' : '')}>Nombre *</div>
              <input type="text" {...register('name', { required: true })} className={clsx({ error: errors?.name })} placeholder="Nombre" />
              {errors.name && <div className="error-message">{errors.name.message}</div>}
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className="input-name">Usuarios *</div>
              <Select
                onChange={(e: any) => setSelectedUsers(e)}
                closeMenuOnSelect={false}
                value={selectedUsers}
                isMulti
                options={organizationUsersSelectOptions}
                placeholder={!organization ? 'Selecciona una organización' : organizationType === OrganizationType.B2C ? 'Usuarios de la tienda' : 'Usuarios del almacén'}
                styles={styles}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                noOptionsMessage={() => 'No hay usuarios asignados a la organización'}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.phone })}>Teléfono *</div>
              <input type="number" {...register('phone', { required: true })} placeholder="Teléfono" className={clsx({ error: errors?.phone })} />
              {errors.phone && <div className="error-message">{errors.phone.message}</div>}
            </div>
            <div className="col">
              <div className={clsx('input-name', { error: errors?.address })}>Dirección *</div>
              <input type="text" {...register('address', { required: true })} className={clsx({ error: errors?.address })} placeholder="Dirección" />
              {errors.address && <div className="error-message">{errors.address.message}</div>}
            </div>
          </div>
        </form>
        <div className="row my-3">
          <div className="col-6">
            <div className={clsx('input-name', { error: errors?.salePrefix })}>Prefijo</div>
            <input type="text" {...register('salePrefix')} className={clsx({ error: errors?.salePrefix })} placeholder="Prefijo" />
            {errors.salePrefix && <div className="error-message">{errors.salePrefix.message}</div>}
          </div>
          {organizationType === OrganizationType.B2C && (
            <div className="col-6">
              <div className={clsx('input-name', { error: errors?.posMoney })}>Dinero en Caja *</div>
              <input
                type="number"
                step={0.01}
                pattern="[0-9]+([\.,][0-9]+)?"
                {...register('posMoney', { required: true, min: 0 })}
                className={clsx({ error: errors?.posMoney })}
                placeholder="Dinero en Caja"
              />
              {errors.posMoney && <div className="error-message">{errors.posMoney.message}</div>}
            </div>
          )}
        </div>
        <div className="row my-3">
          <div className="col">
            <div className={clsx('input-name', { error: errors?.invoicePrefix })}>Prefijo facturas</div>
            <input type="text" {...register('invoicePrefix')} className={clsx({ error: errors?.invoicePrefix })} placeholder="Prefijo facturas" />
            {errors.invoicePrefix && <div className="error-message">{errors.invoicePrefix.message}</div>}
          </div>
          <div className="col">
            <div className={clsx('input-name', { error: errors?.rectificationInvoicePrefix })}>Prefijo facturas rectificativas</div>
            <input type="text" {...register('rectificationInvoicePrefix')} className={clsx({ error: errors?.rectificationInvoicePrefix })} placeholder="Prefijo facturas rectificativas" />
            {errors.rectificationInvoicePrefix && <div className="error-message">{errors.rectificationInvoicePrefix.message}</div>}
          </div>
        </div>
        <div className="row my-3">
          {organizationType === OrganizationType.B2C && (
            <div className="col-12">
              <div className={clsx('input-name', { error: errors?.ticketDescription })}>Texto en el ticket</div>
              <textarea {...register('ticketDescription')} className={clsx('w-100', { error: errors?.ticketDescription })} placeholder="Texto informativo en el ticket" />
              {errors.ticketDescription && <div className="error-message">{errors.ticketDescription.message}</div>}
            </div>
          )}
        </div>
        <div className="row my-3">
          <div className="col-6">
            <div className={clsx('input-name', { error: errors?.ticketDescription })}>Logo</div>
            {logoUrl && logoUrl.length > 0 ? (
              <div className="position-relative container-image d-flex align-items-center justify-content-center me-2 mb-2">
                <LazyLoadImage className="product-image" src={logoUrl} alt="" />
                <span className="delete-image" onClick={onDeleteLogo} title="Eliminar logo">
                  <X color="white" size="15" />
                </span>
              </div>
            ) : (
              <div
                className={clsx('container-image d-flex align-items-center justify-content-center me-2', { clickable: !requesting })}
                title={organizationType === OrganizationType.B2C ? 'Subir logo de la tienda' : 'Subir logo del almacén'}
                onClick={() => {
                  if (requesting) {
                    return;
                  }
                  logoInputFileRef.current.click();
                }}
              >
                <Plus color="white" size="30" />
                <input
                  className="input-size"
                  ref={logoInputFileRef}
                  onChange={onChangeInputLogo}
                  onClick={(event: any) => {
                    event.target.value = null;
                  }}
                  type="file"
                  multiple
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: 'none' }}
                />
              </div>
            )}
          </div>
          <div className="col-6">
            <div className={clsx('input-name', { error: errors?.ticketDescription })}>QR</div>
            {qrUrl && qrUrl.length > 0 ? (
              <div className="position-relative container-image d-flex align-items-center justify-content-center me-2 mb-2">
                <LazyLoadImage className="product-image" src={qrUrl} alt="" />
                <span className="delete-image" onClick={onDeleteQR} title="Eliminar QR">
                  <X color="white" size="15" />
                </span>
              </div>
            ) : (
              <div
                className={clsx('container-image d-flex align-items-center justify-content-center me-2', { clickable: !requesting })}
                title={organizationType === OrganizationType.B2C ? 'Subir QR de la tienda' : 'Subir QR del almacén'}
                onClick={() => {
                  if (requesting) {
                    return;
                  }
                  qrInputFileRef.current.click();
                }}
              >
                <Plus color="white" size="30" />
                <input
                  className="input-size"
                  ref={qrInputFileRef}
                  onChange={onChangeInputQR}
                  onClick={(event: any) => {
                    event.target.value = null;
                  }}
                  type="file"
                  multiple
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: 'none' }}
                />
              </div>
            )}
          </div>
        </div>
        {store && (
          <React.Fragment>
            <div className="contact-info" style={{ marginTop: '10px', marginBottom: '10px' }}>
              Métodos de pago
            </div>
            <div className="row">
              {store.paymentMethods.map((paymentMethod: PaymentMethod) => {
                if (paymentMethod.deleted) {
                  return null;
                }
                return (
                  <div key={paymentMethod.id} className="col-6">
                    <div className="d-flex justify-content-between my-border my-1">
                      <span className="cursor-default">{paymentMethod.isTpv ? `TPV - ${paymentMethod.name}` : paymentMethod.name}</span>
                      {paymentMethod.removable && (
                        <button
                          type="button"
                          className="icon-button"
                          style={{ background: 'none' }}
                          title="Eliminar método de pago"
                          disabled={requesting}
                          onClick={() => deletePaymentMethod(paymentMethod.id)}
                        >
                          <Trash2 size={18} />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-6 my-2">
                <form onSubmit={handleSubmitPm(createPaymentMethod)}>
                  <div className="row d-flex align-items-center">
                    <div className="col-8">
                      <div className={'input-name ' + (errorsPm?.name ? 'error' : '')}>Nuevo método de pago *</div>
                      <input
                        type="text"
                        {...registerPm('name', {
                          required: true,
                          validate: (value: any) => {
                            for (const pm of store.paymentMethods) {
                              if (pm.name.toLowerCase() === value.toLowerCase()) {
                                return 'Ya existe un método de pago con ese nombre';
                              }
                            }
                            return true;
                          },
                        })}
                        className={clsx({ error: errorsPm?.name })}
                        placeholder="Nombre"
                      />
                      {errorsPm.name && <div className="error-message">{errorsPm.name.message}</div>}
                    </div>
                    <div className="col-2">
                      <label className="title-tpv">TPV</label>
                      <input type="checkbox" {...registerPm('isTpv')} style={{ width: '18px', height: '38px' }} />
                    </div>
                    <div className="col-2 text-center">
                      <button type="submit" className="icon-button" style={{ background: 'none' }} title="Crear nuevo método de pago" disabled={requesting}>
                        <Plus />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="row my-3">
          <div className="col">
            <div className={clsx('input-name', { error: errors?.businessName })}>Razón Social *</div>
            <input type="text" {...register('businessName', { required: true })} placeholder="Razón social" className={clsx({ error: errors?.businessName })} />
            {errors.businessName && <div className="error-message">{errors.businessName.message}</div>}
          </div>
          <div className="col">
            <div className={clsx('input-name', { error: errors?.cif })}>CIF *</div>
            <input type="text" {...register('cif', { required: true })} className={clsx({ error: errors?.cif })} placeholder="CIF" />
            {errors.cif && <div className="error-message">{errors.cif.message}</div>}
          </div>
        </div>
        <div className="row my-3">
          <div className="col-6">
            <div className={'input-name'}>Recargo de equivalencia</div>
            <input type="checkbox" {...register('equivalenceSurcharge')} />
          </div>
        </div>
      </div>
      <button className={`save-button`} type="submit" form="store-form" disabled={requesting}>
        Guardar
      </button>
    </Modal>
  );
};

export default StoreModal;
